<script setup>
import { computed, defineProps, onMounted, ref } from 'vue'

const props = defineProps({
  reference: { type: Object, required: true },
  editable: { type: Boolean, required: false, default: false }
});

const searchStaffOutput = staff => staff.contact.name
const assignStaff = (resource, entry, staff) => {
}

// Get find date to determine when weeks change for week breaks
const firstMonth = computed(() => {
  return props.reference?.accepted_version?.dates?.find(d => d.date)?.date || null;
});

const weekNumber = computed(() => (date) => {
  if (!date) return null;

  const d = new Date(date);
  const oneJan = new Date(d.getFullYear(), 0, 1);
  const week = Math.ceil(((d - oneJan) / 86400000 + oneJan.getDay() + 1) / 7);

  return week;
});

// Dates sorted oldest to newested
const sortedDates = computed((dates) => {
  return [...dates].sort((a, b) => new Date(a.date) - new Date(b.date));
});

</script>
<template>
  <div class="whitespace-pre" v-if="reference.accepted_version">
    <div v-for="date in reference.accepted_version.dates" :key="date.id" class="flex gap-8 my-3">
      <div class="w-28">{{ new Date(date.date).toLocaleDateString('en-GB', {
        weekday: 'short', year: 'numeric', month: '2-digit',
        day: '2-digit'
      }) }}</div>
      <div>
        <div>
          {{ new Date(date.date + " " + date.start).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
          -
          {{ new Date(date.date + " " + date.finish).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) }}
        </div>
      </div>
      <div v-for="resource in date.resources" :key="resource.id" class="flex gap-4">
        <div class="flex center-center px-4 py-1 bg-neutral-100 rounded-lg">
          <div>{{ resource.product.short }}</div>
          &nbsp;
          <div class="mb-4 md:mb-0 md:text-right" v-if="!editable">
            {{ resource.staff?.contact?.name ?? "Unstaffed" }}
          </div>
          <searchable-input v-else url="/api/staff/search?query=" :output="searchStaffOutput"
            :placeholder="resource?.staff?.contact?.name ?? 'Search for ' + resource.product?.short"
            @selected="(staff) => assignStaff(resource, entry, staff)" />
        </div>
      </div>
    </div>
  </div>
</template>
