<script setup>
import { useRoute } from 'vue-router'
import apiClient from '@/apiClient.js'
import { onMounted, ref } from 'vue'
import { useBreadcrumbStore } from '@/stores/BreadcrumbStore'
import AddAddress from '@/components/AddAddress'
import SearchContact from '@/components/SearchContact'
import TableList from '@/components/TableList.vue'
import dayjs from 'dayjs'
import Router from '@/router/index.js'
import { useAuthStore } from "@/stores/AuthStore";
import { CloudArrowDownIcon } from '@heroicons/vue/24/solid'
import customerService from "@/services/CustomerService";

var advancedFormat = require('dayjs/plugin/advancedFormat')
dayjs.extend(advancedFormat)

const authStore = useAuthStore();
const route = useRoute()
const breadcrumbStore = useBreadcrumbStore()
const customerId = route.params.id
const customer = ref({})
const tickets = ref()
const editable = ref(false)

onMounted(() => {
  apiClient.get('/api/customer/' + customerId).then(r => {
    customer.value = r.data
    breadcrumbStore.setValue(
      customer.value.company ?? customer.value.contact?.name,
    )

    apiClient.get('/api/customer/' + customerId + '/tickets').then(r1 => {
      tickets.value = r1.data
    })
  })
})

function edit() {
  editable.value = true
}

const saveCustomer = () => {
  editable.value = false
  apiClient
    .post('/api/customer/' + customer.value.id, {
      _method: 'PUT',
      ...customer.value,
    })
    .catch(e => { })
}

const addSelectedContact = inContact => {
  if (customer.value.contacts.some(contact => contact.id === inContact.id))
    return
  customer.value.contacts.push(inContact)
  apiClient
    .post('/api/customer/' + customer.value.id + '/attach-contact', {
      contact_id: inContact.id,
    })
    .catch(e => { })
}

const deleteContact = inContact => {
  customer.value.contacts = customer.value.contacts.filter(
    contact => contact.id !== inContact.id,
  )
  apiClient
    .post('/api/customer/' + customer.value.id + '/detach-contact', {
      contact_id: inContact.id,
    })
    .catch(e => { })
}

const saveContact = contact => {
  apiClient
    .post('/api/contact/' + contact.id, {
      _method: 'PUT',
      ...contact,
    })
    .catch(e => { })
}

const createdAddress = inAddress => {
  customer.value.address.push(inAddress)
  apiClient
    .post('/api/customer/' + customer.value.id + '/attach-address', {
      address_id: inAddress.id,
    })
    .catch(e => { })
}

const deleteAddress = inAddress => {
  customer.value.address = customer.value.address.filter(
    address => address.id !== inAddress.id,
  )
  apiClient
    .post('/api/customer/' + customer.value.id + '/detach-address', {
      address_id: inAddress.id,
    })
    .catch(e => { })
}
const saveAddress = address => {
  apiClient
    .post('/api/address/' + address.id, {
      _method: 'PUT',
      ...address,
    })
    .catch(e => { })
}

const exportInvoices = () => {
  customerService.exportInvoices(customer.value.id)
}
</script>
<template>
  <div>
    <form-box header="Company Information">
      <template v-slot:nav>
        <edit-button @click.prevent="edit" v-if="!editable"></edit-button>
        <save-button @click.prevent="saveCustomer" title="Save" v-if="editable"></save-button>
      </template>
      <form class="grid grid-cols-auto md:grid-cols-2 gap-4">
        <label>Company</label>
        <input type="text" v-model="customer.company" :disabled="!editable" />
        <label>Website</label>
        <input type="text" v-model="customer.website" :disabled="!editable" />
        <label>Supplier Reference</label>
        <input type="text" v-model="customer.supplier_reference" :disabled="!editable" />
        <label>Credit (Days)</label>
        <input type="number" v-model="customer.credit_days" :disabled="!editable" min="0" max="60" />
        <label>Auto Invoicing</label>
        <select v-model="customer.auto_invoicing" :disabled="!editable">
          <option value="1">Enabled</option>
          <option value="0">Disabled</option>
        </select>
        <label>Notes</label>
        <textarea v-model="customer.notes" :disabled="!editable"></textarea>
        <label>Primary Contact</label>
        <select v-model="customer.contact_id">
          <option v-for="contact in customer.contacts" :key="contact" :value="contact.id" :disabled="!editable">
            {{ contact.name }}
          </option>
        </select>
        <label>Primary Address</label>
        <select v-model="customer.primary_address">
          <option v-for="address in customer.address" :key="address" :value="address.id" :disabled="!editable">
            {{ address.data }}
          </option>
        </select>
      </form>
    </form-box>

    <form-box header="Contacts">
      <TableList :modelValue="customer.contacts" :cols="[
        { name: 'Name', row: 'name', editable: true },
        { name: 'Email', row: 'email', editable: true },
        { name: 'Phone', row: 'phone', editable: true },
      ]" :editable="true" :deletable="true" :box="false" @delete="deleteContact" @save="saveContact" />
      <search-contact class="mt-4" @selected="addSelectedContact" />
    </form-box>
    <form-box header="Addresses">
      <TableList :modelValue="customer.address" :cols="[{ row: 'data', editable: true, type: 'textarea' }]"
        :editable="true" :deletable="true" :box="false" @delete="deleteAddress" @save="saveAddress" />
      <AddAddress @created="createdAddress" />
    </form-box>

    <form-box header="Jobs" :collapsible="true">
      <TableList :modelValue="customer.jobs" :cols="[
        { run: val => val.txt, name: 'Reference' },
        { run: val => val.name, name: 'Description' },
        {
          run: val => (val.accepted_at ? 'Accepted' : 'Pending'),
          name: 'Status',
        },
      ]" :box="false" :initialRows="5" @view="val => Router.push('/reference/' + val.id)" />
      <router-link :to="{ name: 'reference-create', query: { customerId: customer.id } }"
        class="btn plus my-2 inline-block">
        + Job
      </router-link>
    </form-box>
    <form-box header="Invoices" v-if="authStore.hasRole('finance-department')">

      <template v-slot:nav>
        <button class="icon clickable" @click="exportInvoices">
          <CloudArrowDownIcon class="icon" />
        </button>
      </template>
      <TableList :modelValue="customer.invoices" :cols="[
        { run: val => val.reference.txt, name: 'Reference' },
        { run: val => val.reference.name, name: 'Description' },
        {
          run: val => dayjs(val.reference.updated_at).format('Do MMM YY'),
          name: 'Updated',
        },
        {
          name: 'Payment',
          run: function (val) {
            return val.paid_at
              ? dayjs(val.paid_at).format('Do MMM YY')
              : 'Unpaid'
          },
          class: 'text-right',
        },
      ]" :box="false" :initialRows="5" @view="val => Router.push('/invoice/' + val.id)" />
      <router-link :to="{ name: 'invoice-create', query: { customerId: customer.id } }"
        class="btn plus my-2 inline-block">
        + Invoice
      </router-link>
    </form-box>

    <form-box header="Tickets" :collapsible="true" v-if="tickets">
      <TableList :modelValue="tickets" :cols="[
        { run: val => val.items[0]?.contact?.name, name: 'Contact' },
        { run: val => val.subject, name: 'Subject' },
        {
          run: val => dayjs(val.updated_at).format('Do MMM YY'),
          name: 'Updated',
        },
      ]" :box="false" @view="val => Router.push('/tickets/' + val.id)" />
      <router-link to="/tickets/create" class="btn plus my-2 inline-block">+ Ticket</router-link>
    </form-box>
  </div>
</template>
