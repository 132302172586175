import apiClient from '@/apiClient.js'

const exportInvoices = async (customer_id) => {
  try {
    const response = await apiClient.post(`/api/customer/${customer_id}/export-invoices`, {}, {
      responseType: 'blob' // <-- Set response type to blob (binary data)
    });

    // Create a Blob from the response
    const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

    // Create a link to trigger download
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `customer_invoices_${customer_id}.xlsx`; // File name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Cleanup
  } catch (e) {
    console.error('Error downloading file:', e);
  }
}

export default {
  exportInvoices,
};

