import { defineStore } from 'pinia'
import { ref } from 'vue'
import apiClient from '@/apiClient.js'

export let useAuthStore = defineStore('auth', {
  state() {
    return {
      authenticated: ref(false),
      loginError: false,
      roles: JSON.parse(localStorage.getItem('roles')) || [], // Load roles from localStorage
    }
  },

  actions: {
    setLoggedIn() {
      this.authenticated = true
    },

    async tryLogin(email, password) {
      if (!email.length || !password.length) {
        return false
      }

      try {
        const response = await apiClient.post('/login', { email, password })

        // Store roles in state and localStorage
        this.roles = response.data?.roles || []
        localStorage.setItem('roles', JSON.stringify(this.roles))

        this.renewTimeoutCookie()
        this.authenticated = true
        localStorage.setItem('user', JSON.stringify(response.data.user))
        return true
      } catch (e) {
        return false
      }
    },

    renewTimeoutCookie() {
      let now = new Date()
      let expiry = now.getTime() + 2 * 60 * 60 * 1000 // 2 hour expiry
      localStorage.setItem('authenticated', expiry)
    },

    async logout() {
      await apiClient.post('/api/user/logout')
        .then(() => {
          // Clear client-side auth data
          localStorage.removeItem('authenticated')
          localStorage.removeItem('user')
          localStorage.removeItem('roles') // Remove stored roles
          this.authenticated = false
          this.roles = []
        })
        .catch(error => {
          console.error("Logout error:", error)
        })
    },

    clearErrors() {
      this.loginError = false
    },
  },

  getters: {
    isLoggedIn() {
      let now = new Date()
      let expiry = localStorage.getItem('authenticated')
      if (parseInt(expiry) > now.getTime()) this.authenticated = true

      return this.authenticated
    },

    hasError() {
      return this.loginError
    },

    user() {
      return JSON.parse(localStorage.getItem('user'))
    },

    name() {
      return JSON.parse(localStorage.getItem('user'))?.name || ''
    },

    email() {
      return JSON.parse(localStorage.getItem('user'))?.email || ''
    },

    hasRole: (state) => (roleName) => {
      return state.roles.includes(roleName)
    },
  },
})

