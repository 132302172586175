<script setup>
import { ref, watch } from "vue";

const props = defineProps({
  options: Array, // Array of filters [{ label: 'All', value: 'all', class: 'bg-indigo-500' }, ...]
  activeFilter: String, // The currently active filter
  containerClass: String, // Additional container classes
});
const emit = defineEmits(["filter-change"]);

const selectedFilter = ref(props.activeFilter);

watch(selectedFilter, (newVal) => {
  emit("filter-change", newVal);
});
</script>

<template>
  <div :class="containerClass"
    :style="{ display: 'grid', gridTemplateColumns: `repeat(${options.length}, minmax(0, 1fr))` }">
    <button v-for="option in options" :key="option.value" @click.prevent="selectedFilter = option.value" :class="[
      'btn-plain', // Default class
      option.class || '', // Custom class from the option
      selectedFilter === option.value ? 'opacity-75' : '' // Highlight active button
    ]">
      {{ option.label }}
    </button>
  </div>
</template>
