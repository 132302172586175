<script setup>
import { onMounted, ref, } from "vue";
import { useListStoreNew } from "@/stores/ListStoreNew";
import PrevNextButtons from "@/components/PrevNextButtons.vue";
import ListSearch from "@/components/ListSearch.vue";
import { PlusCircleIcon, CloudArrowUpIcon, UsersIcon } from "@heroicons/vue/24/solid";
import { useRoute, useRouter, onBeforeRouteUpdate } from "vue-router";
import { usePagination } from "@/composables/usePagination";
import { useBreadcrumbs } from "@/composables/useBreadcrumbs";
import Reference from "@/components/ReferenceNumber.vue"
import ReferenceResources from "@/components/ReferenceResources";
import ReferenceStaff from "@/components/ReferenceStaff.vue";
import dayjs from "dayjs";
import FilterButtons from "@/components/FilterButtons.vue";
import EditButton from "@/components/EditButton.vue";
import SaveButton from "@/components/SaveButton.vue";

var relativeTime = require("dayjs/plugin/relativeTime");
var localisedFormat = require("dayjs/plugin/localizedFormat");
dayjs.extend(relativeTime);
dayjs.extend(localisedFormat)

const list = useListStoreNew();
const counters = ref({});
const route = useRoute();
const router = useRouter();
const filter = ref("");

const { setUrl, changePage } = usePagination(list, filter);
const { setBreadcrumb, clearBreadcrumb } = useBreadcrumbs();

onMounted(async () => {
  list.configure({ remote: "/api/reference", local: "/job", });
  filter.value = route.query?.af ?? "all" // default filter to apply to list
  applyFilter()
  list.fetchIndex()

});

onBeforeRouteUpdate((to, from, next) => {
  if (to.query.page === undefined) {
    load();
  }
  next();
});

const applyFilter = () => {
  list.clearFilters();
  if (filter.value != "all") {
    list.addFilter('prefix', filter.value)
  }
};

const setFilter = (val) => {
  filter.value = val;
  load();
};

const load = () => {
  applyFilter();
  setUrl();
  list.resetPage();
};


const view = (val) => {
  if (event.shiftKey || event.ctrlKey || event.altKey || event.metaKey) {
    return; // Do nothing if any modifier key is held
  }
  router.push({
    name: "reference-view",
    params: { id: val.id },
  });
  setBreadcrumb(val.name);
}; </script>
<template>
  <div>
    <div class="box">
      <div class="flex w-max-full lg:w-full space-between">
        <ListSearch class="flex-1 mr-4" url="/api/reference/search?query="
          :output="(val) => { return val.txt + ' - ' + val.name }" @selected="view" />
        <div class="flex gap-2">
          <router-link to="/reference/create" class="btn accent" title="Create New">
            <PlusCircleIcon class="icon" />
          </router-link>
          <router-link to="/reference/import" class="btn accent" title="Create New">
            <CloudArrowUpIcon class="icon" />
          </router-link>
        </div>
      </div>

      <FilterButtons :options="[
        { label: 'All', value: 'all', class: 'bg-sky-500' },
        { label: 'Events', value: 'm1e', class: 'bg-indigo-500' },
        { label: 'PTS', value: 'm1p', class: 'bg-teal-500' },
        { label: '365', value: 'm1s', class: 'bg-rose-500' },
        { label: 'Training', value: 'm1t', class: 'bg-pink-500' }
      ]" :activeFilter="filter.value" containerClass="grid mt-4 gap-2" @filter-change="setFilter" />

    </div>

    <FormBox :header="item.name" v-for="item in list.getIndex" :key="item.id">
      <template v-slot:header>
        <Reference :reference="item" class="w-24" /> &nbsp;
      </template>
      <template v-slot:nav>
        <EditButton :disableTitle="true" @edit="item.editable = true" v-if="!item.editable" />
        <SaveButton v-else :disableTitle="true" @save="item.editable = false" />
      </template>
      <ReferenceStaff :reference="item" :editable="item.editable" v-if="item.accepted_at" />
    </FormBox>

    <PrevNextButtons :showPrev="list.showPrev" :showNext="list.showNext" @prev="changePage(-1)" @next="changePage(1)" />
  </div>
</template>
